import React from "react"
import { getBorderColor, getTextColor, Target } from "../../../utils"

const Procedure = ({ target }: { target: Target }) => {
  const items = [
    {
      title: "お問い合わせ",
      description:
        "少しでも気になる事、施設の利用を考えているもしくは見学したい場合に、ＬＩＮＥ・ＴＥＬ・ＦＡＸ・ＭＡＩＬでお問合せください。\n​※ＬＩＮＥにお友達登録して頂けるだけでお手軽で便利です。",
    },
    {
      title: "ご相談",
      description: "ぜひ、一度お越しください。",
    },
    {
      title: "見学・面談・体験",
      description:
        "初めての場所は不安が多いと思います。どんな場所なのか、利用しているお子さん・スタッフの先生たちの様子を実際に見ていただければと思います。",
    },
    {
      title: "支給決定・受給者証発行",
      description:
        "市町村の福祉相談窓口や障害児相談支援事業所などに相談します。窓口で地域の児童発達支援事業所リストなどの情報を提供してくれます。",
    },
    {
      title: "市町村への相談",
      description:
        "相談支援事業所で担当相談支援員さんに作成していただいたサービス利用計画書をもとに支給日が決まり受給者証が発行となります。",
    },
    {
      title: "ご契約・ご利用開始",
      description:
        "アセスメントの情報をもとに作成した個別支援計画に基づいてに支援いたします。",
    },
  ]
  return (
    <div className="mt-12 md:mt-16">
      {items.map((item, index) => {
        return (
          <div className="mb-10 md:mb-8 md:flex" key={item.title}>
            <div
              className={`flex items-center justify-center w-24 h-24 mx-auto text-3xl border-4 rounded-full ${getTextColor(
                target
              )} md:w-40 md:h-40 md:mx-0 ${getBorderColor(
                target
              )} border-opacity-20 shrink-0 font-kiwiMaru`}
            >
              {index + 1}
            </div>
            <div className="mt-8 text-center md:my-auto md:ml-16 md:text-left">
              <div className="text-xl font-kiwiMaru">{item.title}</div>
              <div className="whitespace-pre-wrap mt-7">{item.description}</div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default Procedure
