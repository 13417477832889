import React from "react"

const About = () => (
  <div className="mt-12 md:mt-16">
    児童発達支援・放課後等デイサービス（児童福祉法）の支給決定（通所受給者）を受けている方・または受ける予定の方にご利用いただけます。
    「お友達とうまく遊べない・落ち着きがない・勉強に追いついていかない」等お悩みのご家族様の相談場所としてご利用下さい。
  </div>
)

export default About
